@import url("https://fonts.googleapis.com/css2?family=Poetsen+One&family=Sevillana&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cactus+Classical+Serif&family=Poetsen+One&family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cactus+Classical+Serif&family=Poetsen+One&family=Poppins&family=Roboto+Slab:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");

.navbar {
  padding: 15px 30px;
  /* background-color: #363434; */
  margin: 0px 15px;
  color: #791615;
  border-radius: 12px;
  text-decoration: none;
}

.navbar:hover {
  background-color: #791615;
  color: white;
}

.poetsen-one-regular {
  font-family: "Poetsen One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.login-input {
  width: 280px;
  height: 40px;
  padding: 3px 15px;
  font-size: 16px;
  border-radius: 12px;
  border: 2px solid #ccc;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.login-input::placeholder {
  color: #999;
  font-style: italic;
}

.login-input:focus {
  border-color: #791615;
  box-shadow: 0 0 8px rgba(170, 47, 47, 0.6);
  outline: none;
}

.login-input:hover {
  border-color: #999;
}

.login-label {
  font-size: 18px;
  color: #333;
  display: block;
  margin-bottom: 5px;
}

/* Button styling */
.login-button {
  display: block;
  width: 150px;
  padding: 10px 20px;
  margin: 20px auto;
  font-size: 16px;
  color: #fff;
  background-color: #791615;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.login-button:hover {
  background-color: #a05552;
}

.login-button:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.6);
}

/* Forgot link styling */
.forgot-link {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.forgot-link:hover {
  text-decoration: underline;
}

.app-container {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 10px;
  max-width: 1000px; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
}

.app-container-box {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  max-width: 1000px; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
}

.apps-element {
  transition: transform 0.3s ease;
}

.apps-element:hover {
  transform: scale(1.2);
}

.folder-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  max-width: 1000px; /* Adjust as needed */
  margin: 0 auto;
  padding: 20px;
}

.cactus-classical-serif-regular {
  font-family: "Cactus Classical Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .popup-content {
  background-color: #fff;
  width: 800px;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
} */

.popup-content {
  background-color: #fff;
  position: relative;
  padding: 20px;
  width: 80%;
  max-width: 800px;
  max-height: 80vh; /* Adjust as needed */
  overflow-y: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Profile Site CSS  */

.profile-button {
  cursor: pointer;
  width: 200px;
  border-radius: 12px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.profile-button:hover {
  background-color: #007bff;
  color: white;
}
.profile-button:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.6);
}

/* Forget Password Page  */

.forget-password-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

.forget-password-box {
  background: #ffffff;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}

.forget-password-box h2 {
  margin-bottom: 20px;
  color: #343a40;
}

.forget-password-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
}

.forget-password-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.forget-password-button:hover {
  background-color: #0056b3;
}

/* Create Apps and Folder  */

.app-create {
  text-decoration: none;
  color: #000000;
  font-weight: 700;
  background: #87ceeb;
  padding: 15px 25px;
  border-radius: 12px;
  cursor: pointer;
}

.app-create:hover {
  background: #791615;
  color: white;
}
.cancel-button {
  text-decoration: none;
  color: #fff;
  width: 150px;
  font-weight: 700;
  background: #f80707;
  padding: 15px 25px;
  border-radius: 12px;
  cursor: pointer;
}

.cancel-button:hover {
  background: #791615;
  color: white;
}

.folder-create {
  text-decoration: none;
  color: #000000;
  font-weight: 700;
  background: #fcd354;
  padding: 15px 25px;
  border-radius: 12px;
  cursor: pointer;
}

.folder-create:hover {
  background: #791615;
  color: white;
}
.icon-delete {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background: #791615;
  padding: 15px 25px;
  border-radius: 12px;
  cursor: pointer;
}

.icon-delete:hover {
  background: #d76616;
  color: white;
}

.folder-image {
  width: 250px;
  height: 35px;
  position: relative; /* Necessary for absolute positioning of children */
  overflow: hidden; /* Hide any overflowing content */
}

.user-create {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background: #168ac0;
  padding: 15px 25px;
  border-radius: 12px;
  cursor: pointer;
}

.user-create:hover {
  background: #791615;
  color: white;
}

.notice-create {
  text-decoration: none;
  color: white;
  font-weight: 700;
  background: #166ec0;
  padding: 15px 25px;
  border-radius: 12px;
  cursor: pointer;
}

.notice-create:hover {
  background: #b93b39;
  color: white;
}

.oswald {
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
}

/* Loading ..................  */

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  margin-bottom: 20px;
  border-top: 16px solid #791615;
  border-bottom: 16px solid #000000;
  border-left: 16px solid #fff;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
